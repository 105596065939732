<template>
  <Loader :show="loading" />
  <HeaderHome />
  <div class="container-fluid body">
    <div class="container">
      <div class="row body-box">
        <div class="col-md-12">
          <h1>
            Minha conta
            <span class="pull-right small editaccount">
              <v-link href="/minha-conta/atualizar-dados/">
                <span class="glyphicon glyphicon-pencil"></span> Alterar meus dados
              </v-link>
            </span>
          </h1>

          <p class="text-justify">
            Olá, confira abaixo os kits adquiridos e suas informações.
          </p>


          <div class="row" v-if="events_pending.length >= 1">
            <div class="col-md-12">
              <h3>Inscrições em Análise</h3>
              <div class="row">
                <div class="col-md-4 inscricoes" v-for="(evento, index) in events_pending.filter((ev) => ev?.event)"
                  :key="index">
                  <div class="card card-default" @click="ver_mais(evento?.id)">
                    <img class="card-img-top" :src="evento?.event?.image" :alt="evento?.event?.name">
                    <div class="card-body">
                      <p><b>Evento:</b> {{ evento?.event?.name }}</p>
                      <p><b>Data do Evento:</b> {{ (evento?.event?.start_at)?.split('-').reverse().join('/') }}</p>
                      <p><b>Modalidade:</b> {{ evento?.modality?.name }}</p>
                      <p class="mais-detalhes"> Ver mais detalhes </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row" v-if="events_open.length >= 1">
            <div class="col-md-12">
              <h3>Minhas Inscrições Ativas</h3>
              <div class="row">
                <div class="col-md-4 inscricoes" v-for="(evento, index) in events_open" :key="index">
                  <div class="card card-default" @click="ver_mais(evento?.id)">
                    <img class="card-img-top" :src="evento?.event?.image" :alt="evento.event?.name">
                    <div class="card-body">
                      <p><b>Evento:</b> {{ evento?.event?.name }}</p>
                      <p><b>Data do Evento:</b> {{ (evento?.event?.start_at).split('-').reverse().join('/') }}</p>
                      <p><b>Modalidade:</b> {{ evento?.modality?.name }}</p>
                      <p class="mais-detalhes"> Ver mais detalhes </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="events_closed.length >= 1">
            <div class="col-md-12">
              <h3>Minhas Inscrições Concluidas</h3>
              <div class="row">
                <div class="col-md-4 inscricoes" v-for="(evento, index) in events_closed" :key="index">
                  <div class="card card-default" @click="ver_mais(evento.id)">
                    <img class="card-img-top" :src="evento?.event?.image" :alt="evento.event?.name">
                    <div class="card-body">
                      <p><b>Evento:</b> {{ evento?.event?.name }}</p>
                      <p><b>Data do Evento:</b> {{ (evento?.event?.start_at).split('-').reverse().join('/') }}</p>
                      <p><b>Modalidade:</b> {{ evento?.modality?.name }}</p>
                      <p class="mais-detalhes"> Ver mais detalhes </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row" v-if="events_open.length == 0 && events_closed.length == 0 && events_pending.length == 0">
            <div class="col-md-12 newuser">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h3>Você ainda não possui inscrições.</h3>
                </div>
              </div>
            </div>
          </div>

          <Atalhos />
        </div>
      </div>
    </div>
  </div>
  <Newsletter />
  <Footer />
  <FooterEnd />
</template>

<script>
import { defineComponent } from "vue";
import HeaderHome from "@/components/HeaderHome.vue";
import Footer from "@/components/Footer.vue";
import FooterEnd from "@/components/FooterEnd.vue";
import Newsletter from "@/components/Newsletter.vue";
import Atalhos from "@/components/Atalhos";
import VLink from "@/components/VLink";
import Loader from "@/components/Loader";
import User from "@/api/User";
import Auth from "@/api/Auth";

export default defineComponent({
  name: "MinhaConta",
  components: {
    HeaderHome,
    Footer,
    FooterEnd,
    Atalhos,
    Newsletter,
    VLink,
    Loader
  },
  data() {
    return {
      contents: {
        name: "",
        description: "",
      },
      events_pending: [],
      events_open: [],
      events_closed: [],
      loading: true,
      slug: "",
    };
  },
  methods: {
    ver_mais(index) {
      this.$router.push('/minha-conta/' + index + '/ver-detalhes/');

      window.scrollTo(0, 0);
    },
    event_pending(object) {
      return object.status == 2;
    },
    open_events(object) {
      let today = new Date();
      let event_date = new Date(object.event?.finish_at);
      let duration = 10;
      event_date.setTime(event_date.getTime() + (duration * 24 * 60 * 60 * 1000));

      return today <= event_date && object.status != 2;
    },
    closed_events(object) {
      let today = new Date();
      let event_date = new Date(object.event?.finish_at);
      let duration = 10;
      event_date.setTime(event_date.getTime() + (duration * 24 * 60 * 60 * 1000));
      return today > event_date && object.status != 2;
    }
  },
  mounted() {
    User.get_events(Auth.token()).then((request) => {
      if (request.status == 200) {
        this.events_pending = request.data.filter(this.event_pending);
        this.events_open = request.data.filter(this.open_events);
        this.events_closed = request.data.filter(this.closed_events);
      }
      this.loading = false;
    });
  },
});
</script>

<style scoped>
.editaccount {
  font-size: 14px;
  font-weight: 100;
  padding-top: 10px;
}

.editaccount a {
  text-decoration: none;
}

#cphCorpo_gdvAdmin {
  border-collapse: collapse;
}

.card-body p {
  padding: 4px;
}

.inscricoes .card:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.card-img-top {
  width: 100%;
  aspect-ratio: 358/271;
  object-fit: fill;
}

.inscricoes .card {
  transition: all .2s ease-in-out;
}

.mais-detalhes {
  text-align: end;
  color: #d9534f;
  position: absolute;
  right: 5px;
  bottom: 0;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
}

.inscricoes .card:hover .mais-detalhes {
  opacity: 1;
}

.card .card-body b {
  color: #f1aa2a;
}

.newuser {
  margin-bottom: 30px;
  margin-top: 30px;
}
</style>
